<template>
    <div class="home">
        <div class="alien">
            <div class="head">
                <p>punkverse is captured!*</p>
                <p>*you're all shit</p>
            </div>
            <div class="speech">bring us the ransom</div>
            <img src="@/assets/images/arrows.gif" />
            <button @click="mint" class="mint">mint</button>
        </div>
        <div class="mobile">
            <p>nuttin to do here on mobile!<br><br>use ya effing junk desktop to mint the hell out of us</p>
        </div>
        <connect-wallet
            :connect="connect"
            v-if="connect"
            v-on:close="connect = $event"
            v-on:success="connectSuccess($event)"
        ></connect-wallet>
    </div>
</template>

<script>
import { ProviderKeeper } from '@waves/provider-keeper';
import { ProviderCloud } from '@waves.exchange/provider-cloud';

import ConnectWallet from "wavesmarketplace-app/src/components/ConnectWallet.vue";

export default {
    name: "Home",
    data() {
        return {
            connect: false,
            wallet: {},
            walletStatus: false,
        };
    },
    components: {
        ConnectWallet,
    },
    async mounted() {
        const data = window.localStorage.getItem("loginChoice");
        if (!data) {
            this.connect = true;
        } else {
            this.walletStatus = true;
            this.wallet = JSON.parse(data);
        }
    },
    methods: {
        async connectSuccess(address) {
            this.walletStatus = true;
            this.wallet.address = address;
        },
        drop() {
            window.location.href = "https://twitter.com/WavesPunks/status/1554044272134987777";
        },
        async mint() {
            const data = JSON.parse(window.localStorage.getItem("loginChoice"));

            if (data.choice == "keeper") {
                const authData = { data: "https://memaliens.com/" };
                await window.signer
                    .setProvider(new ProviderKeeper(authData))
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else if (data.choice == "email") {
                window.signer.setProvider(new ProviderCloud());
            }

            await window.signer
                .invoke({
                    dApp: window.contractAddress,
                    fee: 900000,
                    payment: [],
                    call: {
                        function: "mint",
                        args: [],
                    },
                })
                .broadcast()
                .then((res) => {
                    console.log("Your transaction has been broadcast to network!");
                    console.log(res);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
};
</script>

<style scoped>
@media only screen and (min-height: 1520px) {
    .alien {
        background-position-y: center !important;
    }
}

@media only screen and (max-width: 768px) {
    .alien {
        display: none !important;
    }

    .mobile {
        display: flex !important;
    }
}

.home {
    /* width: 100vw; */
    min-height: 100vh;
    background-image: url("../assets/images/back.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.alien {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    background-image: url("../assets/images/fuck.png");
    background-repeat: no-repeat;
    background-size: 700px;
    background-position-x: right;
    background-position-y: 20px;
}

.alien::after {
    position: absolute;
    top: 260px;
    right: 65px;
    content: url('../assets/images/tatoo.svg');
}

.head,
.speech,
.mint {
    margin-left: 70px;
}

.head > p {
    margin: 0;
    font-family: "Bangers";
    font-style: normal;
    font-weight: 400;
    color: #70ff00;
}

.head > p:first-child {
    margin-top: 20px;
    font-size: 154.688px;
    line-height: 165px;
}

.head > p:last-child {
    font-size: 44.6523px;
    line-height: 48px;
}

.speech {
    position: relative;
    margin-top: 40px;
    padding: 15px 35px;
    /* border: 3.05537px solid #000000; */
    border-radius: 38.1921px;
    background: #ffffff;
    font-family: "Bangers";
    font-style: normal;
    font-weight: 400;
    font-size: 76.3842px;
    line-height: 81px;
    color: #000000;
}

.speech::after {
    position: absolute;
    top: -34px;
    right: -32px;
    content: url('../assets/images/speech.svg');
}

.alien > img {
    width: 770px;
    margin-left: -40px;
}

.mint {
    padding: 8px 130px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: #ff0000;
    font-family: "Bangers";
    font-style: normal;
    font-weight: 400;
    font-size: 155px;
    line-height: 165px;
    color: #ffffff;
}

.mint:hover {
    cursor: pointer;
}

.mobile {
    display: none;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0px 40px;
}

.mobile > p {
    margin: 0;
    font-family: "Bangers";
    font-style: normal;
    font-weight: 400;
    font-size: 44.6523px;
    line-height: 48px;
    color: #70ff00;
}
</style>